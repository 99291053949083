import Axios from 'axios'
import { API_LOGGER } from '@/service/index';
import _ from 'lodash';
import store from '@/store/index';
import { SET_CP_DEVICE_INFO } from '@/store/constants/mutations';
import { GET_CP_DEVICE_INFO, GET_CP_DOMAIN, GET_CP_URL, GET_DEVICE_ID, GET_MS_ID, GET_HARMONY_ID } from '@/store/constants/getters';
import { CENTERAL_POINT_URL_DOMAIN } from '@/store/constants/actions';
import { ENDPOINTS } from './endpoints/index';


const timeout = 1 * 60 * 1000
const axios_name = {
  cp_detect: 'centeralpoint-detect',
  relay: 'relay',
  relay_proxy: 'relay_proxy',
  meeting: 'meeting',
}
export const API = {
  Axios: {
    central_point: {
      v1: Axios.create({
        name: axios_name.cp_detect,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
      v2: Axios.create({
        name: axios_name.cp_detect,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 2,
      }),
    },
    relay_proxy: {
      v1: Axios.create({
        name: axios_name.relay_proxy,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
    },
    relay: {
      v1: Axios.create({
        name: axios_name.relay,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
    },
    meeting: {
      v1: Axios.create({
        name: axios_name.meeting,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: timeout,
        version: 1,
      }),
    },
  },
  get errorMessages(){
    return {
      default: `There seems to be a issue try again later`,
      timeout: `It's taking longer than usual to connect to our servers. Please check your internet connection and try again.`,
      network: `Unable to establish a connection to our servers. Please ensure you're connected to the internet and try again.`,
      server_down: `Our servers are currently unavailable. We apologise for the inconvenience. Please try again later.`,
    }
  },
  init(){
    try {
      this.Axios.central_point.v1.interceptors.request.use(this.onRequest.bind(this))
      this.Axios.central_point.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.Axios.central_point.v2.interceptors.request.use(this.onRequest.bind(this))
      this.Axios.central_point.v2.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.Axios.relay_proxy.v1.interceptors.request.use(this.onRequest.bind(this))
      this.Axios.relay_proxy.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.Axios.relay.v1.interceptors.request.use(this.onRequest.bind(this))
      this.Axios.relay.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.Axios.meeting.v1.interceptors.request.use(this.onRequest.bind(this))
      this.Axios.meeting.v1.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
    } catch (error) {
      console.log('API',error)
    } finally {
      if(process.env.NODE_ENV !== 'production'){
        window.voip_app.API = this
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  async onRequest(config){
    API_LOGGER.info(config.url,config)
    const meeting_id = config?.extra_data?.meeting_id
    const api_name = config.name
    const url = config?.url
    const method = config?.method?.toLowerCase?.() ?? ''
    const attaching_device_id = config?.extra_data?.attaching?.device_id
    const attaching_ms_harmony = config?.extra_data?.attaching?.ms_harmony
    const attaching_fp_data = config?.extra_data?.attaching?.fp_data
    const attaching_ip_address = config?.extra_data?.attaching?.ip_address
    try {
      if (api_name==axios_name.relay_proxy){
        const cp_url = store.getters[GET_CP_URL]
        if(!cp_url){
          const { data } = await API.endpoints.dns.getCP({
            domain: store.getters[GET_CP_DOMAIN]
          })
          store.dispatch(CENTERAL_POINT_URL_DOMAIN,data)
        }
        if(!store.getters[GET_CP_DEVICE_INFO]?.ip){
          await API.endpoints.dns.deviceInfo()
        }
      }
    } finally {
      if(api_name==axios_name.cp_detect){
        let url = ``
        if(config.version==1) {
          url = `${process.env.VUE_APP_CENTRAL_POINT_DETECT}v1/`
        } else {
          url = `${process.env.VUE_APP_CENTRAL_POINT_DETECT}v2/`
        }
        config.baseURL = url;
      } else if(api_name==axios_name.meeting){
        const res = await this.endpoints.auth.meetingServer(meeting_id ?? '')
        config.baseURL = res?.data?.harmony ? `${res?.data?.harmony}/api/v1/` : ''
        config.jitsi_url = res?.data?.jitsi_url ? `${res?.data?.jitsi_url}http-bind` : ''
      } else if(api_name==axios_name.relay_proxy){
        const cp_url = store.getters[GET_CP_URL]
        config.baseURL=`${cp_url}api/v1/`
        if(attaching_device_id) {
          const device_id = store.getters[GET_DEVICE_ID]
          if(method=='get'){
            if(url.includes(':device_id')) {
              config.url = url.replace(':device_id',device_id)
            } else {
              _.set(config,'params.device_id',device_id)
            }
          }
          else {
            _.set(config,'data.device_id',device_id)
          }
        }
        if(attaching_ip_address) {
          const fp_data = store.getters[GET_CP_DEVICE_INFO]
          if(method=='get'){
            if(url.includes(':ip')) {
              config.url = url.replace(':ip',fp_data.ip)
            } else {
              _.set(config,'params.ip',fp_data.ip)
            }
          }
          else {
            _.set(config,'data.ip',fp_data.ip)
          }
        }
        if(attaching_fp_data) {
          const fp_data = store.getters[GET_CP_DEVICE_INFO]
          if(method=='get'){
            _.set(config,'params.fp_data',fp_data)
          }
          else {
            _.set(config,'data.fp_data',fp_data)
          }
        }
        if(attaching_ms_harmony) {
          const mothership_id = store.getters[GET_MS_ID]
          const harmony_id = store.getters[GET_HARMONY_ID]
          if(method=='get'){
            _.set(config,'params.ms_server',mothership_id)
            _.set(config,'params.server_template',harmony_id)
          }
          else {
            _.set(config,'data.ms_server',mothership_id)
            _.set(config,'data.server_template',harmony_id)
          }
        }
      } else if(api_name==axios_name.relay){
        const { data } = await API.endpoints.auth.signupServer()
        config.baseURL=data?.server ? `${data.server}/api/v1/` : ''
      } 
      // eslint-disable-next-line no-unsafe-finally
      return config;
    }
  },
  async onError(ex){
    API_LOGGER.danger(ex?.config?.url,{ex})
    let default_message = ex?.config?.extra_data?.default_message || this.errorMessages.default
    let message = ex?.config?.extra_data?.error_message || ''
    let response_error = ex?.response?.data ?? {}
    let errors = {}
    let api_error = false
    const status_code = ex?.response?.status
    errors = response_error?.errors ?? {}
    const response_description = response_error?.description
    const response_message = response_error?.message
    if(errors instanceof Object){
      Object.keys(errors).forEach((key)=>{
        errors[key] = typeof errors[key] == 'string' ? [errors[key]] : errors[key]
      })
      response_error.errors=errors
    }
    if(!message) {
      if(ex.message == "Network Error"){
        if(!window?.navigator?.onLine){
          message = this.errorMessages.network
        } else {
          message = this.errorMessages.server_down
        }
      } else if(ex.message.includes('timeout')){
        message = this.errorMessages.timeout
      } else if(status_code==500){
        api_error=true
        message = default_message
      } else {
        api_error=true
        message = response_description || response_message || ex.message || default_message
      }
    }
    console.log(message)
    const response = {
      own_errors: errors,
      own_message: message,
      api_error: api_error,
      response_error: response_error,
      ...ex,
      message: ex.message,
      stack: ex.stack,
      cancel: Axios.isCancel?.(ex),
    }
    throw response
  },
  async onSuccess(response){
    API_LOGGER.success(response?.config?.url,response)
    const api_name = response?.config?.name
    const device_info = response?.config?.extra_data?.device_info
    if (api_name==axios_name.meeting) {
      const jitsi_url = response.config.jitsi_url
      API_LOGGER.log(jitsi_url)
      return {
        ...response,
        data: {
          ...response.data,
          jitsi_url
        }
      }
    } else if (device_info && api_name==axios_name.cp_detect) {
      store.commit(SET_CP_DEVICE_INFO,response.data)
    } else {
      return response
    } 
  },
  endpoints: ENDPOINTS,
}
