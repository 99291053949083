import { API } from "../index"

export const AUTH_ENDPOINTS = {
  fetchMotherships(){
    return API.Axios.relay_proxy.v1.request({
      method: 'GET',
      url: 'relayservers',
    })
  },
  fetchHarmonies(params={}){
    return API.Axios.relay_proxy.v1.request({
      method: 'GET',
      url: 'harmoneyservers',
      params,
    })
  },
  toggleSignupTestMode(data={}){
    return API.Axios.relay_proxy.v1.request({
      method: 'POST',
      url: 'test-mode',
      data,
      extra_data: {
        attaching: {
          device_id: true,
        }
      },
    })
  },
  fetchSignupTestMode(params={}){
    return API.Axios.relay_proxy.v1.request({
      url: 'get-test-mode/:device_id',
      method: 'GET',
      params,
      extra_data: {
        attaching: {
          device_id: true,
        }
      },
    })
  },
  userServer(data={},extra_data){
    return API.Axios.relay_proxy.v1.request({
      url: `logins`,
      method: 'POST',
      data,
      extra_data: {
        ...extra_data,
        attaching: {
          device_id: true,
          fp_data: true,
        }
      },
    })
  },
  forgotpassword(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: `forgot/password`,
      method: 'POST',
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  signupResendEmail(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: `resend-email-pincode`,
      method: 'POST',
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  signupServer(params={}){
    return API.Axios.relay_proxy.v1.request({
      url: `default-relay-server`,
      method: 'GET',
      params,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  meetingServer(id){
    return API.Axios.relay_proxy.v1.request({
      url: `meet/${id}`,
      method: 'GET',
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  signup(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: `signups`,
      method: 'POST',
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  verifyemail(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "verifyemail",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  resendemail(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "resend-email",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  updateprofiledata(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "signups", /*"signupsinfo",*/
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  register(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "register-user",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
          fp_data: true,
        }
      },
    })
  },
  verifypincode(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "verifyemail", /*"verify-pincode",*/
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  verifynumber(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "verifynumber",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  checkSignupStatus(id='',params={}){
    return API.Axios.relay_proxy.v1.request({
      url: `signup/jobs/${id}`,
      method: "GET",
      params,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  resendpincode(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "resend-pincode",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  fetchnumbers(params={},token){
    return API.Axios.relay_proxy.v1.request({
      url: "aqlnumbers",
      method: "GET",
      params,
      cancelToken: token,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  fetchregions(){
    return API.Axios.relay_proxy.v1.request({
      url: "get-usa-regions",
      method: "GET",
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  fetchregiongroups(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "region-did-group",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  fetchcities(data={}){
    // fetchcities(params={}){
    return API.Axios.relay_proxy.v1.request({
      url: "aql-cities",
      // method: "GET",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  acceptOwnerShip(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "verify-ownership",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  checkSignupAvailable(params={}){
    return API.Axios.relay_proxy.v1.request({
      url: "signup-available",
      method: "POST",
      params,
      extra_data: {
        attaching: {
          device_id: true,
          // fp_data: true,
          ip_address: true,
          ms_harmony: true,
        }
      },
    })
  },
  verifyinvitation(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "verify-invitation",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  invitationinfo(data={},extra_data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "update-user-account",
      method: "POST",
      data,
      extra_data: {
        ...extra_data,
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  updatesignupnumber(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "update-signup-number",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  checkusernameavailable(data={},extra_data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "username-origanization-lookup",
      method: "POST",
      data,
      extra_data: {
        ...extra_data,
        attaching: {
          device_id: true,
          ms_harmony: true,
        },
      },
    })
  },
  checkpromocode(data={}){
    return API.Axios.relay_proxy.v1.request({
      url: "check-promocode-validity",
      method: "POST",
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  getPlatforms(params={}){
    return API.Axios.relay_proxy.v1.request({
      url: `fetch-platforms`,
      method: "GET",
      params,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  getRegions(params={}){
    return API.Axios.relay_proxy.v1.request({
      url: `fetch-regions`,
      method: "GET",
      params,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
  settingRegionPlatform(data){
    return API.Axios.relay_proxy.v1.request({
      url: `authenticate-setting-page`,
      method: 'POST',
      data,
      extra_data: {
        attaching: {
          device_id: true,
          ms_harmony: true,
        }
      },
    })
  },
}